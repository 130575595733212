import * as React from "react";
import "../App.css";
import { useEffect } from "react";
import { useAuth } from "../utils/auth";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Switch from "@mui/material/Switch";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { API } from "../api/api";
import Alert from "../component/alert";

export default function Planogram() {
  const { id } = useParams();
  const { token } = useAuth();

  let navigate = useNavigate();

  const headers = {
    Authorization: `Bearer ${token.token}`,
  };

  const unSelectedProduct = {
    stroke: "rgb(255, 51, 51)",
    fill: "rgb(255, 51, 51)",
  };
  const selectedProduct = {
    stroke: "rgb(45, 92, 211)",
    fill: "rgb(45, 92, 211)",
  };

  const [alertType, setAlertType] = React.useState("");
  const [alertShow, setAlertShow] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");

  const [scanType, setScanType] = React.useState("");
  const [actualPlano, setActualPlano] = React.useState([]);
  const [referencePlano, setReferencePlano] = React.useState([]);
  const [actualPlanogramSwitch, setActualPlanogramSwitch] = React.useState(
    false
  );
  const [referencePlanogramId, setReferencePlanogramId] = React.useState(0);
  const [
    referencePlanogramSwitch,
    setReferencePlanogramSwitch,
  ] = React.useState(false);
  const [origin, setOrigin] = React.useState(0);
  const [panImage, setPaneImage] = React.useState("");
  const [showPane, setShowPane] = React.useState(false);
  const [currentIssue, setCurrentIssue] = React.useState({});
  const [currentImageWidth, setCurrentImageWidth] = React.useState(0);
  const [currentImageHeight, setCurrentImageHeight] = React.useState(0);
  const [referenceImageWidth, setReferenceImageWidth] = React.useState(0);
  const [referenceImageHeight, setReferenceImageHeight] = React.useState(0);

  const [currentIssueDetailsList, setCurrentIssueDetailsList] = React.useState(
    []
  );
  const [
    referenceIssueDetailsList,
    setReferenceIssueDetailsList,
  ] = React.useState([]);

  const location = useLocation();

  useEffect(() => {
    getIssueList(id);
    if (location.state !== null) {
      setCurrentIssue(location.state.currentIssue);
      setPaneImage(
        API + `/nemesis/preview?ref-id=${location.state.currentIssue?.sku.ean}`
      );
      setShowPane(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertShow(false);
  };

  async function getIssueList(id) {
    await fetch(API + `/nemesis/api/scan?scan-id=${id}`, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then(async (issueList) => {
        setScanType(issueList.scans[0].scan.scanType);
        setCurrentImageWidth(issueList.scans[0].scan.thumbnailW);
        setCurrentImageHeight(issueList.scans[0].scan.thumbnailH);
        setActualPlano(issueList.scans[0].planogram);
        if (issueList.scans[0].scan.issues) {
          getReferencePlanogram(issueList.scans[0].scan.planogramRefId);
          let newList = issueList.scans[0].scan.issues.map((list) => ({
            ...list,
            selected: false,
          }));

          if (location.state !== null) {
            let loopingList = newList.filter((list) => {
              if (list.id === location.state.currentIssue.id) {
                list.selected = true;
                return list;
              }
            });
            setCurrentIssueDetailsList(loopingList);
          }
          setOrigin(1);
        } else {
          let newList = issueList.scans[0].scan.facings.map((list) => ({
            ...list,
            selected: false,
          }));
          setCurrentIssueDetailsList(newList);
          setOrigin(2);
        }
      })
      .catch((e) => {
        setAlertMessage(`Impossible to reach the server: ${e}`);
        setAlertType("error");
        setAlertShow(true);
      });
  }

  async function getReferencePlanogram(referenceId) {
    setReferencePlanogramId(referenceId);
    await fetch(API + `/nemesis/api/scan?scan-id=${referenceId}`, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((issueList) => {
        setReferenceImageWidth(issueList.scans[0].scan.thumbnailW);
        setReferenceImageHeight(issueList.scans[0].scan.thumbnailH);
        setReferencePlano(issueList.scans[0].planogram);
        let newList = issueList.scans[0].scan.facings.map((list) => ({
          ...list,
          selected: false,
        }));
        setReferenceIssueDetailsList(newList);
      })
      .catch((e) => {
        setAlertMessage(`Impossible to reach the server: ${e}`);
        setAlertType("error");
        setAlertShow(true);
      });
  }

  async function solveIssue(issueId) {
    await fetch(
      API + `/nemesis/api/issue/solve?issue-id=${issueId}&action=${"solve"}`,
      {
        method: "GET",
        headers: headers,
      }
    )
      .then((response) => response.text())
      .then((resp) => {
        if (resp === "OK") {
          setAlertMessage(`Issue solved successfully`);
          setAlertType("success");
          setAlertShow(true);

          setTimeout(() => {
            navigate(`/home/scan/${id}`);
          }, 1000);
        }
      })
      .catch((e) => {
        setAlertMessage(`Impossible to reach the server: ${e}`);
        setAlertType("error");
        setAlertShow(true);
      });
  }

  function setIssueTypeColors(issueType) {
    let issueColor = { color: "", backgroundColor: "" };

    if (!issueType) {
      issueColor.color = "#FFFFFF";
      issueColor.backgroundColor = "#FFFFFF";

      return issueColor;
    }

    if (issueType === "Missing facings") {
      issueColor.color = "#EBB958";
      issueColor.backgroundColor = "#FFF4DF";
    } else if (issueType === "Out of Stock") {
      issueColor.color = "#EB5858";
      issueColor.backgroundColor = "#FEE9E7";
    } else {
      issueColor.color = "#2D5CD3";
      issueColor.backgroundColor = "#DDDCFF";
    }
    return issueColor;
  }

  function openSidePanel(reference, sku, planogramType) {
    if (panImage !== "") {
      setPaneImage((x) => (x = ""));
    }
    selectProduct(sku, planogramType);
    setShowPane(true);
    setCurrentIssue(sku);
    setTimeout(() => {
      setPaneImage(API + `/nemesis/preview?ref-id=${reference}`);
    }, 300);
  }

  function selectProduct(prdct, planogramType) {
    if (prdct.selected) {
      return;
    } else {
      if (planogramType === "actual") {
        if (referenceIssueDetailsList.length > 0) {
          let unselectList = referenceIssueDetailsList.filter((obj) => {
            if (obj.selected === true) {
              obj.selected = false;
            }
            return obj;
          });
          setReferenceIssueDetailsList(unselectList);
        }
        let selectedItemList = currentIssueDetailsList.filter((item) => {
          if (item.selected === true) {
            item.selected = false;
          } else {
            if (item.id === prdct.id) {
              item.selected = true;
            }
          }
          return item;
        });
        setCurrentIssueDetailsList(selectedItemList);
      } else {
        let unselectList = currentIssueDetailsList.filter((obj) => {
          if (obj.selected === true) {
            obj.selected = false;
          }
          return obj;
        });
        setCurrentIssueDetailsList(unselectList);
        let selectedItemList = referenceIssueDetailsList.filter((item) => {
          if (item.selected === true) {
            item.selected = false;
          } else {
            if (item.id === prdct.id) {
              item.selected = true;
            }
          }
          return item;
        });
        setReferenceIssueDetailsList(selectedItemList);
      }
    }
  }

  const handleChangeActual = (event) => {
    setActualPlanogramSwitch(event.target.checked);
  };

  const handleChangeReference = (event) => {
    setReferencePlanogramSwitch(event.target.checked);
  };

  return (
    <div>
      <Alert
        message={alertMessage}
        show={alertShow}
        type={alertType}
        handleClose={handleClose}
        color={alertType === "success" ? "#2DB417" : "#EB5858"}
        backgroundColor={alertType === "success" ? "#DFFFDA" : "#FEE9E7"}
      />
      <div className="d-flex flex-column">
        <div className="d-flex flex-row align-items-center">
          

          {currentIssueDetailsList.length !== 0 ? (
            scanType !== "External image" ? (
                <div className="d-flex flex-row align-items-center">
              <p>Actual Planogram's image</p>
              <Switch
                checked={actualPlanogramSwitch}
                onChange={handleChangeActual}
                inputProps={{ "aria-label": "controlled" }}
              /></div>
            ) : null
          ) : null}
        </div>
        <div className="d-flex align-items-center">
          <svg
            style={{
              width: currentImageWidth,
              height: currentImageHeight,
              transition: "all .2s",
              display: actualPlanogramSwitch ? "none" : "flex",
            }}
          >
            <image
              href={API + `/nemesis/image?image-id=${id}`}
              className="image-fetched"
              style={{ width: currentImageWidth, height: currentImageHeight }}
            />
            {origin === 1
              ? currentIssueDetailsList[0].boxes.map((item, i) => {
                  return (
                    <rect
                      key={i}
                      onClick={() => {
                        openSidePanel(
                          currentIssueDetailsList[0].sku?.id,
                          currentIssueDetailsList[0],
                          "actual"
                        );
                      }}
                      width={item.w * currentImageWidth}
                      height={item.h * currentImageHeight}
                      x={item.x * currentImageWidth}
                      y={item.y * currentImageHeight}
                      style={{
                        cursor: "pointer",
                        fill: currentIssueDetailsList[0].selected
                          ? selectedProduct.fill
                          : unSelectedProduct.fill,
                        fillOpacity: "0.3",
                        strokeWidth: "1",
                        stroke: currentIssueDetailsList[0].selected
                          ? selectedProduct.stroke
                          : unSelectedProduct.stroke,
                      }}
                    />
                  );
                })
              : currentIssueDetailsList.map((item, i) => {
                  return (
                    <rect
                      key={i}
                      onClick={() =>
                        openSidePanel(item.sku?.ean, item, "actual")
                      }
                      width={item.wr * currentImageWidth}
                      height={item.hr * currentImageHeight}
                      x={item.xr * currentImageWidth}
                      y={item.yr * currentImageHeight}
                      style={{
                        cursor: "pointer",
                        fill: item.selected
                          ? selectedProduct.fill
                          : unSelectedProduct.fill,
                        fillOpacity: "0.3",
                        strokeWidth: "1",
                        stroke: item.selected
                          ? selectedProduct.stroke
                          : unSelectedProduct.stroke,
                      }}
                    />
                  );
                })}
          </svg>
          <div style={{ border: "2px solid rgb(190, 190, 190)" }}>
            <svg
              style={{
                width: currentImageWidth,
                height: currentImageHeight,
                transition: "all .2s",
                display: actualPlanogramSwitch ? "flex" : "none",
                backgroundColor: "#F7F9FB",
              }}
            >
              <image className="image-fetched" />

              {actualPlano.facings?.map((res, i) => {
                return (
                  <image
                    key={i}
                    onClick={() => openSidePanel(res.sku?.ean, res, "actual")}
                    href={
                      API +
                      `/nemesis/plano?ref-id=${res.sku?.ean}&rw=${
                        res.facing.w * currentImageWidth
                      }&rh=${res.facing.h * currentImageHeight}`
                    }
                    width={res.facing.w * currentImageWidth}
                    height={res.facing.h * currentImageHeight}
                    x={res.facing.x * currentImageWidth}
                    y={res.facing.y * currentImageHeight}
                    style={{ cursor: "pointer" }}
                  ></image>
                );
              })}
              {actualPlano.columnSeparators?.map((res, i) => {
                return (
                  <rect
                    key={i}
                    width={res.separator.w * currentImageWidth}
                    height={res.separator.h * currentImageHeight}
                    x={res.separator.x * currentImageWidth}
                    y={res.separator.y * currentImageHeight}
                    style={{
                      fill: "rgb(190,190,190)",
                      fillOpacity: "0.3",
                      strokeWidth: "1",
                      stroke: "rgb(190,190,190)",
                    }}
                  ></rect>
                );
              })}
              {actualPlano.shelfSeparators?.map((res, i) => {
                return (
                  <rect
                    key={i}
                    width={res.separator.w * currentImageWidth}
                    height={res.separator.h * currentImageHeight}
                    x={res.separator.x * currentImageWidth}
                    y={res.separator.y * currentImageHeight}
                    style={{
                      fill: "rgb(190,190,190)",
                      fillOpacity: "0.3",
                      strokeWidth: "1",
                      stroke: "rgb(190,190,190)",
                    }}
                  ></rect>
                );
              })}
            </svg>
          </div>
        </div>
        <div className="d-flex align-items-center">
          {origin === 1 ? (
            <div>
              <div className="d-flex flex-row align-items-center">
                <p>Reference Planogram's image</p>
                <Switch
                  checked={referencePlanogramSwitch}
                  onChange={handleChangeReference}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
              <div className="d-flex align-items-center">
                <svg
                  style={{
                    width: referenceImageWidth,
                    height: referenceImageHeight,
                    transition: "all .2s",
                    display: referencePlanogramSwitch ? "none" : "flex",
                  }}
                >
                  <image
                    href={
                      API + `/nemesis/image?image-id=${referencePlanogramId}`
                    }
                    className="image-fetched"
                    style={{
                      width: referenceImageWidth,
                      height: referenceImageHeight,
                    }}
                  />
                  {referenceIssueDetailsList.length > 0
                    ? referenceIssueDetailsList.map((item) => {
                        return (
                          <rect
                            key={item.id}
                            onClick={() =>
                              openSidePanel(item.sku?.ean, item, "reference")
                            }
                            width={item.wr * referenceImageWidth}
                            height={item.hr * referenceImageHeight}
                            x={item.xr * referenceImageWidth}
                            y={item.yr * referenceImageHeight}
                            style={{
                              cursor: "pointer",
                              fill: item.selected
                                ? selectedProduct.fill
                                : unSelectedProduct.fill,
                              fillOpacity: "0.3",
                              strokeWidth: "1",
                              stroke: item.selected
                                ? selectedProduct.stroke
                                : unSelectedProduct.stroke,
                            }}
                          />
                        );
                      })
                    : null}
                </svg>
                <div style={{ border: "2px solid rgb(190, 190, 190)" }}>
                  <svg
                    style={{
                      width: referenceImageWidth,
                      height: referenceImageHeight,
                      transition: "all .2s",
                      display: referencePlanogramSwitch ? "flex" : "none",
                      backgroundColor: "#F7F9FB",
                    }}
                  >
                    <image
                      className="image-fetched"
                      style={{
                        width: referenceImageWidth,
                        height: referenceImageHeight,
                      }}
                    />

                    {referencePlano.facings?.map((res, i) => {
                      return (
                        <image
                          key={i}
                          onClick={() =>
                            openSidePanel(res.sku?.ean, res, "reference")
                          }
                          href={
                            API +
                            `/nemesis/plano?ref-id=${res.sku?.ean}&rw=${
                              res.facing.w * referenceImageWidth
                            }&rh=${res.facing.h * referenceImageHeight}`
                          }
                          width={res.facing.w * referenceImageWidth}
                          height={res.facing.h * referenceImageHeight}
                          x={res.facing.x * referenceImageWidth}
                          y={res.facing.y * referenceImageHeight}
                          style={{ cursor: "pointer" }}
                        ></image>
                      );
                    })}
                    {referencePlano.columnSeparators?.map((res, i) => {
                      return (
                        <rect
                          key={i}
                          width={res.separator.w * referenceImageWidth}
                          height={res.separator.h * referenceImageHeight}
                          x={res.separator.x * referenceImageWidth}
                          y={res.separator.y * referenceImageHeight}
                          style={{
                            fill: "rgb(190,190,190)",
                            fillOpacity: "0.3",
                            strokeWidth: "1",
                            stroke: "rgb(190,190,190)",
                          }}
                        ></rect>
                      );
                    })}
                    {referencePlano.shelfSeparators?.map((res, i) => {
                      return (
                        <rect
                          key={i}
                          width={res.separator.w * referenceImageWidth}
                          height={res.separator.h * referenceImageHeight}
                          x={res.separator.x * referenceImageWidth}
                          y={res.separator.y * referenceImageHeight}
                          style={{
                            fill: "rgb(190,190,190)",
                            fillOpacity: "0.3",
                            strokeWidth: "1",
                            stroke: "rgb(190,190,190)",
                          }}
                        ></rect>
                      );
                    })}
                  </svg>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div
        className="detail-side-bar"
        style={{
          transition: "all .2s",
          opacity: showPane ? "1" : "0",
          visibility: showPane ? "visible" : "hidden",
        }}
      >
        <div
          className="d-flex justify-content-end col-md-12 mt-1"
          style={{ cursor: "pointer" }}
        >
          <CloseSharpIcon
            onClick={() => {
              setPaneImage("");
              setShowPane(false);
            }}
          />
        </div>
        <div className="sliding-pane d-flex mt-4">
          <p className="col-md-6 fs-5 fw-bold">Scan analysis</p>
          <div
            className="pane-product-status col-md-6"
            style={{
              color: setIssueTypeColors(currentIssue?.issueType).color,
              backgroundColor: setIssueTypeColors(currentIssue?.issueType)
                .backgroundColor,
            }}
          >
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                marginBottom: "0px",
              }}
            >
              #{currentIssue?.issueType}
            </p>
          </div>
        </div>
        <div className="product-pane-image-block me-0 d-flex justify-content-center">
          <div
            style={{
              backgroundImage: `url(${panImage})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              display: "flex",
              justifyContent: "center",
              backgroundPosition: "center",
            }}
            className="product-pane-image"
          />
        </div>
        <div className="d-flex mt-3" style={{ width: "100%" }}>
          <div className="col-md-6">
            {origin === 1 ? (
              <button
                style={{ width: "90%", zIndex: "9999", visibility: "hidden" }}
                className="action-button d-flex justify-content-center align-items-center"
              >
                <p className="fw-bold mb-0 small">Actions</p>
                <KeyboardArrowDownRoundedIcon />
              </button>
            ) : null}
          </div>

          <div className="col-md-6 float-right">
            {origin === 1 ? (
              <button
                disabled={
                  currentIssue?.status === "Solved" ||
                  "boxes" in currentIssue === false
                    ? true
                    : false
                }
                style={{
                  width: "90%",
                  zIndex: "9999",
                  opacity:
                    currentIssue?.status === "Solved" ||
                    "boxes" in currentIssue === false
                      ? "0.6"
                      : "1",
                }}
                className="scan-button"
                onClick={() => {
                  solveIssue(currentIssue?.id);
                }}
              >
                <p className="fw-bold mb-0 small">Mark as Solved</p>
              </button>
            ) : null}
          </div>
        </div>
        <div className="planogram-box mt-3">
          <div className="d-flex align-items-center" style={{ height: "30px" }}>
            <div className="col-md-8">
              <p className="fw-bold fs-4 mb-0">Planogram</p>
            </div>
            <div className="col-md-4 d-flex justify-content-center align-items-center">
              <p className="fw-bold mb-0">{currentIssue?.status}</p>
            </div>
          </div>
          <div className="mt-3">
            <p className="fw-bold fs-6">{currentIssue?.sku?.name}</p>
          </div>
          <div className="mt-4 d-flex" style={{ height: "20px" }}>
            <p className="fw-bold fs-6">{"Facings detected:  "}</p>
            <p className="fw-light fs-6 ms-1">
              {currentIssue === undefined
                ? null
                : "boxes" in currentIssue === false
                ? " - "
                : currentIssue?.boxes.length}
            </p>
          </div>
          <div className="mt-4 d-flex" style={{ height: "20px" }}>
            <p className="fw-bold fs-6">{"Price: "}</p>
            <p className="fw-light fs-6 ms-1">
              {currentIssue?.sku?.price.toFixed(2)} €
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
