import "../App.css";
import * as React from "react";
import { useEffect } from "react";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import {
  CircularProgress,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
  Fade,
  Backdrop,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput,
  Chip,
  useTheme,
} from "@mui/material";
import { API } from "../api/api";
import { useAuth } from "../utils/auth";
import { getRoutePath } from "../hooks/useParsePath";
import { Button, Table } from "react-bootstrap";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import Alert from "../component/alert";
import moment from "moment";
import useInterval from "../hooks/useInterval";

export default function Home() {
  const { token } = useAuth();
  const headers = {
    Authorization: `Bearer ${token?.token}`,
  };

  const boxModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  function handleChangeChip(event) {
    const {
      target: { value },
    } = event;
    setSelectedCategories(
      typeof value === "string" ? value.split(",") : value
    );
  }

  const theme = useTheme();

  const [scanList, setScanList] = React.useState([]);
  const [alertType, setAlertType] = React.useState("");
  const [alertShow, setAlertShow] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [scanDisabled, setScanDisabled] = React.useState(false);
  const [planogramViewDisabled, setPlanogramViewDisabled] = React.useState(
    false
  );

  const [countryList, setCountryList] = React.useState([]);

  const [countries, setCountries] = React.useState([]);
  const [selectedCountries, setSelectedCountries] = React.useState([]);

  const [categories, setCategories] = React.useState([]);
  const [selectedCategories, setSelectedCategories] = React.useState([]);

  const [openModal, setOpenModal] = React.useState(false);

  function handleOpenModal() {
    setOpenModal(true);
  }
  function handleCloseModal() {
    setOpenModal(false);
    setSelectedCategories([])
    setSelectedCountries([])
    setCountries([])
    setCategories([])
  }

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const path = getRoutePath(location, params);

  useEffect(() => {
    if (path.at(-1) === "/") {
      navigate(`/home`);
    }
    getScanList();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  useInterval(async () => {
    if (path !== location.pathname) {
      return;
    }
    const processingScan = scanList.filter((scans) => {
      return scans.scan.scanStatus === 201;
    });
    if (processingScan.length >= 1) {
      getScanList();
    }
  }, 5000);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertShow(false);
  };

  function permiToScan(lst) {
      const scans = lst?.filter((scans) => {
        return scans.scan.scanStatus === 201;
      });
      const viewPlanogram = lst?.filter((scans) => {
        return scans.scan.scanStatus === 202 && scans.scan.scanType === 'Planogram acquisition'
      });

      if(viewPlanogram.length !== 0){
        setPlanogramViewDisabled(false)
      }else{
        setPlanogramViewDisabled(true)
      }

      if (scans?.length === 0) {
        setScanDisabled(false);
      } else {
        setScanDisabled(true);
      }
    }
  

  async function getScanList() {
    await fetch(API + `/nemesis/api/scan/list`, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((scanList) => {
        if (scanList) {
          const lst = scanList.scans.reverse();
          setScanList(lst);
          permiToScan(lst);
        }
      })
      .catch((e) => {
        setAlertMessage(`Impossible to retrieve the scan list: ${e}`);
        setAlertType("error");
        setAlertShow(true);
      });
  }

  async function setStart() {
    const acquiredPlanogram = scanList.filter((scan) => {
      return scan.scan.scanType === "Planogram acquisition";
    });
    if (acquiredPlanogram.length < 1) {
      setAlertMessage(`You have to acquire a planogram first`);
      setAlertType("error");
      setAlertShow(true);

      return;
    }
    await fetch(API + `/nemesis/api/scan/start`, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.text())
      .then((scoreList) => {
        if (scoreList === "OK") {
          getScanList();
          setAlertMessage(`The scan is started`);
          setAlertType("success");
          setAlertShow(true);
          setScanDisabled(true);
        } else {
          setAlertMessage(`The scan for some reason not started`);
          setAlertType("error");
          setAlertShow(true);
        }
      })
      .catch((e) => {
        setAlertMessage(`Error occurred while scannin the shelf: ${e}`);
        setAlertType("error");
        setAlertShow(true);
      });
  }

  function handleFileChange(e) {
    if (!e.target.files[0]) {
      return;
    } else {
      handleUpload(e.target.files[0]);
    }
  }

  // eslint-disable-next-line no-unused-vars
  async function handleUpload(file) {
    const catParam = selectedCategories.join(`_${selectedCountries},`);

    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await fetch(
        API + `/nemesis/api/upload?scope=` + catParam + `_${selectedCountries}`,
        {
          method: "POST",
          body: formData,
          headers: headers,
        }
      );
      if (response.ok) {
        setAlertMessage("Image successfully uploaded");
        setAlertType("success");
        setAlertShow(true);
        setScanDisabled(true);
        getScanList();
        setOpenModal(false);
        setSelectedCategories([]);
        setSelectedCountries([]);
      } else {
        setAlertMessage("Failed to upload the file due to errors");
        setAlertType("error");
        setAlertShow(true);
      }
    } catch (error) {
      setAlertMessage(`Error occurred while uploading the file: ${error}`);
      setAlertType("error");
      setAlertShow(true);
    }
  }

  async function acquirePlanogram() {
    await fetch(API + `/nemesis/api/scan/acquire-planogram`, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.text())
      .then((resp) => {
        if (resp === "OK") {
          getScanList();
          setAlertMessage("Planogram acquisition started");
          setAlertType("success");
          setAlertShow(true);
          setScanDisabled(true);
        }
      })
      .catch((e) => {
        setAlertMessage(`Error occurred while acquiring the planogram: ${e}`);
        setAlertType("error");
        setAlertShow(true);
      });
  }

  async function seePlanogram() {
    await fetch(API + `/nemesis/api/scan/planogram`, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((resp) => {
        if (resp) {
          let id = resp.scans[0].scan.id;
          navigate(`planogram/${id}`);
        } else {
          setAlertMessage("Failed to download the file due to errors");
          setAlertType("error");
          setAlertShow(true);
        }
      })
      .catch((e) => {
        setAlertMessage(`Error occurred while downloading the planogram: ${e}`);
        setAlertType("error");
        setAlertShow(true);
      });
  }

  function getCategories() {
    fetch(API + `/nemesis/api/scan/available-categories`, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((cat) => {
        const country = cat.countries.map((arr) => {
          return arr.country;
        });
        setCountries(country);
        setCountryList(cat.countries);
      })
      .catch((e) => {
        setAlertMessage(`Impossible to reach the server: ${e}`);
        setAlertType("error");
        setAlertShow(true);
      });
  }

  function handleChangeCoutry(value) {
    const cat = countryList.filter((c) => {
      return c.country === value;
    });

    setSelectedCountries(value);
    setCategories(cat[0].categories);
  }

  return (
    <div className="homeGrid">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={handleCloseModal}
        onTransitionEnter={getCategories}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openModal}>
          <Box sx={boxModalStyle}>
            <div className="d-flex flex-column">
              <div className="d-flex flex-row align-items-center w-100">
                <p className="fw-bold" style={{ minWidth: "150px" }}>
                  Select a country
                </p>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    <p>Country</p>
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedCountries}
                    label="Country"
                    onChange={(value) => {
                      handleChangeCoutry(value.target.value);
                    }}
                    style={{}}
                  >
                    {countries.length >= 1
                      ? countries.map((c, i) => {
                          return (
                            <MenuItem key={i} value={c}>
                              <p>{c}</p>
                            </MenuItem>
                          );
                        })
                      : null}
                  </Select>
                </FormControl>
              </div>
              <div className="d-flex flex-row align-items-center w-100 mt-3">
                <p className="fw-bold" style={{ minWidth: "150px" }}>
                  Select categories
                </p>
                <FormControl fullWidth>
                  <InputLabel id="demo-multiple-chip-label">
                    <p>Categories</p>
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    label="Categories"
                    value={selectedCategories}
                    onChange={handleChangeChip}
                    input={
                      <OutlinedInput id="select-multiple-chip" label="Categories" />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {categories.sort().map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        style={getStyles(name, selectedCategories, theme)}
                      >
                        <p>{name}</p>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-5 w-100">
                <label
                  htmlFor="filePicker"
                  className="scan-button"
                  style={{
                    minWidth: "180px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor:
                      selectedCountries.length === 0 ||
                      selectedCategories.length === 0
                        ? ""
                        : "pointer",
                    height: "50px",
                    fontSize: "18px",
                    opacity:
                      selectedCountries.length === 0 ||
                      selectedCategories.length === 0
                        ? "0.6"
                        : 1,
                  }}
                >
                  Take a scan from file
                </label>
                <input
                  id="filePicker"
                  type="file"
                  disabled={
                    selectedCountries.length === 0 ||
                    selectedCategories.length === 0
                      ? true
                      : false
                  }
                  style={{ display: "none" }}
                  className="scan-button me-3"
                  accept="image/jpeg"
                  onChange={handleFileChange}
                />
              </div>
              <div className="d-flex justify-content-center align-items-center mt-2"><p className="small">*We accept only jpeg/jpg images</p></div>
            </div>
          </Box>
        </Fade>
      </Modal>
      {path !== location.pathname ? (
        <Outlet />
      ) : (
        <div>
          <Alert
            message={alertMessage}
            show={alertShow}
            type={alertType}
            handleClose={handleClose}
            color={alertType === "success" ? "#2DB417" : "#EB5858"}
            backgroundColor={alertType === "success" ? "#DFFFDA" : "#FEE9E7"}
          />
          <div>
            <p className="fw-bold fs-4">Store scans list</p>
          </div>
          <div className="filter-box d-flex align-items-center mt-5">
            <button
              disabled={scanDisabled}
              className="scan-button d-flex justify-content-center"
              style={{ minWidth: "100px", opacity: scanDisabled ? "0.6" : "1" }}
              onClick={() => setStart()}
            >
              <p className="fw-bold">Take a scan</p>
            </button>
            <button
              className="scan-button d-flex justify-content-center ms-4"
              disabled={scanDisabled}
              style={{ minWidth: "165px", opacity: scanDisabled ? "0.6" : "1" }}
              onClick={handleOpenModal}
            >
              <p className="fw-bold mb-0">Take a scan from file</p>
            </button>

            <div className="ms-3 filter-separator" />
            <FilterListRoundedIcon className="ms-3" />
            <div className="d-flex justify-content-end w-100">
              <button
                className="scan-button me-3"
                disabled={scanDisabled}
                onClick={acquirePlanogram}
                style={{ opacity: scanDisabled ? "0.6" : "1" }}
              >
                <p className="fw-bold">Acquire planogram</p>
              </button>
              <button
                className="scan-button me-3"
                disabled={planogramViewDisabled}
                onClick={seePlanogram}
                style={{ opacity: planogramViewDisabled ? "0.6" : "1" }}
              >
                <p className="fw-bold">See planogram view</p>
              </button>
            </div>
          </div>
          <TableContainer className="pt-4">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="table-header-text">Scan Id</TableCell>
                  <TableCell className="table-header-text">Date</TableCell>
                  <TableCell className="table-header-text">Time</TableCell>
                  <TableCell className="table-header-text">Type</TableCell>
                  <TableCell className="table-header-text">Scope</TableCell>
                  <TableCell className="table-header-text">Status</TableCell>
                  <TableCell className="table-header-text">
                    Issue detected
                  </TableCell>
                  <TableCell className="table-header-text">
                    Issue solved
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {scanList.map((row) => {
                  let status =
                    row.scan.scanStatus === 202 ? "Completed" : "Processing";
                  return (
                    <TableRow key={row.scan.id}>
                      <TableCell className="table-header-text">
                        {row.scan.id}
                      </TableCell>
                      <TableCell className="table-header-text">
                        {moment(row.scan.date).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell className="table-header-text">
                        {moment(row.scan.date).utc().format("HH:mm A")}
                      </TableCell>
                      <TableCell className="table-header-text">
                        {row.scan.scanType}
                      </TableCell>
                      <TableCell className="table-header-text">
                        {row.scan.scanScope}
                      </TableCell>
                      {}
                      <TableCell
                        className="table-header-text"
                        style={{ maxWidth: "140px" }}
                      >
                        <div
                          className="status-grid-box d-flex flex-row"
                          style={{
                            backgroundColor:
                              row.scan.scanStatus === 202
                                ? "#DFFFDA"
                                : "#FEE9E7",
                            color:
                              row.scan.scanStatus === 202
                                ? "#2DB417"
                                : "#EB5858",
                          }}
                        >
                          <p className="mb-0 col-md-9 justify-content-center d-flex">
                            {status}
                          </p>
                          {row.scan.scanStatus === 201 ? (
                            <CircularProgress
                              style={{ color: "#EB5858" }}
                              size={15}
                              className="col-md-2"
                            />
                          ) : null}
                        </div>
                      </TableCell>
                      <TableCell className="table-header-text">
                        {row.scan.scanResult?.issuesDetected}
                      </TableCell>
                      <TableCell className="table-header-text">
                        {row.scan.scanResult?.issuesSolved}
                      </TableCell>
                      <TableCell style={{ minWidth: "170px" }}>
                        {status === "Processing" ? null : (
                          <Button
                            onClick={() => {
                              if (row.scan.scanType === "On demand") {
                                navigate(`scan/${row.scan.id}`);
                              } else {
                                navigate(`planogram/${row.scan.id}`);
                              }
                            }}
                            className=""
                            style={{
                              color: "black",
                              opacity: "1",
                              padding: "0px",
                              border: "0px",
                              backgroundColor: "white",
                              textTransform: "none",
                              boxShadow: 0,
                            }}
                          >
                            <div className="d-flex align-items-center">
                              <p className="m-0 p-0">Explore scan</p>
                              <ArrowForwardOutlinedIcon className="ms-3" />
                            </div>
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
}
