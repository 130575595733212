import { NavLink, useNavigate } from "react-router-dom"
import { useAuth } from "../utils/auth"
import { useEffect } from "react"

export default function NotFound() {

  const { token } = useAuth()
  const navigate = useNavigate();


  useEffect(() =>{
    if(!token){
      navigate('/')
    }else{
      navigate('home')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  return (
    <div className="d-flex align-items-center justify-content-center" style={{ height: '100vh' }}>
      <div className="d-flex flex-column">
      <h2>Page not found!</h2>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia alias cupiditate ad nostrum doloribus iste tempora quisquam excepturi repellat, fugit cumque dolore magni possimus inventore neque provident! Sunt, quo eos?</p>

      <p>Go to the <NavLink to="/">Homepage</NavLink>.</p>
      </div>
    </div>
  )
}