import * as React from "react";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tab,
  Tabs,
  LinearProgress,
} from "@mui/material";
import { Box } from "@mui/system";
import { API } from "../api/api";
import { useAuth } from "../utils/auth";
import { useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import { getRoutePath } from "../hooks/useParsePath";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import PropTypes from "prop-types";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import Alert from "../component/alert";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <span>{children}</span>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Details() {
  const { id } = useParams();

  const { token } = useAuth();

  const headers = {
    Authorization: `Bearer ${token.token}`,
  };

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const path = getRoutePath(location, params);

  const [alertType, setAlertType] = React.useState("");
  const [alertShow, setAlertShow] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");

  const [tabValue, setTabValue] = React.useState(0);
  const [categories, setCategories] = React.useState([]);
  const [issuesSolved, setIssueSolved] = React.useState(0);
  const [issuesDetected, setIssueDetected] = React.useState(0);
  const [issueDetailsList, setIssueDetailsList] = React.useState([]);

  useEffect(() => {
    if (path.at(-1) === "/") {
      navigate(`home/scan/${id}`);
    }

    getIssueList(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertShow(false);
  };

  function getIssueList(id) {
    fetch(API + `/nemesis/api/scan?scan-id=${id}`, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((issueList) => {
        setIssueDetailsList(issueList.scans[0].scan.issues);
        parseCategoryBoxes(issueList.scans[0].scan.issues);
        setIssueDetected(issueList.scans[0].scan.scanResult.issuesDetected);
        setIssueSolved(issueList.scans[0].scan.scanResult.issuesSolved);
      })
      .catch((e) => {
        setAlertMessage(`Impossible to reach the server: ${e}`);
        setAlertType("error");
        setAlertShow(true);
      });
  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function setIssueTypeColors(issueType) {
    let issueColor = { color: "", backgroundColor: "" };
    if (issueType === "Missing facings") {
      issueColor.color = "#EBB958";
      issueColor.backgroundColor = "#FFF4DF";
    } else if (issueType === "Out of Stock") {
      issueColor.color = "#EB5858";
      issueColor.backgroundColor = "#FEE9E7";
    } else {
      issueColor.color = "#2D5CD3";
      issueColor.backgroundColor = "#DDDCFF";
    }
    return issueColor;
  }

  function parseCategoryBoxes(issueList) {
    const cat = issueList.filter(
      (obj, index) =>
        issueList.findIndex((item) => item.category === obj.category) === index
    );

    setCategories(cat);
  }

  function goToPreview(issue) {
    navigate(`planogram/${id}`, { state: { currentIssue: issue } });
  }

  return (
    <div>
      {path !== "/home/scan/:id" ? (
        <Outlet />
      ) : (
        <div>
          <Alert
            message={alertMessage}
            show={alertShow}
            type={alertType}
            handleClose={handleClose}
            color={alertType === 'success' ? '#2DB417' : '#EB5858'}
            backgroundColor={alertType === 'success' ? '#DFFFDA' : '#FEE9E7'}
          />
          <div className="d-flex flex-row">
            <div className="block1">
              <div className="d-flex flex-row">
                <p className="align-items-center">Issue detected</p>
                <div className="issueBlocksIcons">
                  <ErrorOutlineIcon />
                </div>
              </div>
              <div>
                <p className="blocksTextResults">{issuesDetected}</p>
              </div>
            </div>
            <div className="block2 ms-4">
              <div className="d-flex">
                <p className="align-items-center">Issue solved</p>
                <div className="issueBlocksIcons">
                  <CheckCircleOutlineIcon />
                </div>
              </div>
              <div>
                <p className="blocksTextRxesults">{issuesSolved}</p>
              </div>
            </div>
          </div>

          <div className="Tabs">
            <Box className="p-0">
              <Tabs
                value={tabValue}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="p-0"
              >
                <Tab label="Issue List" {...a11yProps(0)} />
                <Tab label="Category" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
              <TableContainer className="pt-4">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="table-header-text">EAN</TableCell>
                      <TableCell className="table-header-text">Name</TableCell>
                      <TableCell className="table-header-text">
                        Category
                      </TableCell>
                      <TableCell className="table-header-text">
                        Issue Type
                      </TableCell>
                      <TableCell className="table-header-text">
                        Priority
                      </TableCell>
                      <TableCell className="table-header-text">
                        Status
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {issueDetailsList.map((row, i) => {
                      let statusColor =
                        row.status === "Solved"
                          ? "#2DB417"
                          : row.status === "Blocked"
                          ? "#EB5858"
                          : "rgb(163, 194, 252)";
                      let priorityColor =
                        row.priority === "High"
                          ? "#EB5858"
                          : row.priority === "Medium"
                          ? "#EBB958"
                          : "grey";
                      return (
                        <TableRow key={i}>
                          <TableCell className="table-header-text">
                            {row.sku.ean}
                          </TableCell>
                          <TableCell className="table-header-text">
                            {row.sku.name}
                          </TableCell>
                          <TableCell className="table-header-text">
                            {row.sku.category}
                          </TableCell>
                          <TableCell className="table-header-text" style={{ minWidth: '165px' }}>
                            <div
                              className="status-grid-box"
                              style={{
                                fontWeight: 'bold',
                                color: setIssueTypeColors(row.issueType).color,
                                backgroundColor: setIssueTypeColors(
                                  row.issueType
                                ).backgroundColor,
                              }}
                            >
                              #{row.issueType}
                            </div>
                          </TableCell>
                          <TableCell
                            className="table-header-text"
                            style={{
                              color: priorityColor,
                              fontWeight: "bold",
                            }}
                          >
                            {row.priority}
                          </TableCell>
                          <TableCell className="table-header-text">
                            <ul className="mb-0 ps-3">
                              <li
                                style={{
                                  color: statusColor,
                                  fontWeight: "bold",
                                }}
                              >
                                {row.status}
                              </li>
                            </ul>
                          </TableCell>
                          <TableCell style={{ minWidth: "170px" }}>
                            <Button
                              variant="contained"
                              className="table-header-text"
                              style={{
                                color: "black",
                                opacity: "1",
                                padding: "0px",
                              }}
                              onClick={() => goToPreview(row)}
                            >
                              <div className="d-flex flex-row">
                                <p className="mb-0">Explore issues</p>
                                <ArrowForwardOutlinedIcon className="ms-3" />
                              </div>
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              {categories.map((cat, i) => {
                return (
                  <div
                    key={i}
                    className="category-box fw-light mt-4"
                  >
                    <p className="fw-bold mb-2">{cat.sku.category}</p>
                    <p className="small fw-lighter lh-1 mb-2">
                      Toothbrushes, toothpastes, and many others
                    </p>

                    <Box sx={{ width: "100%", mr: 1 }}>
                      <LinearProgress
                        variant="determinate"
                        value={parseInt((issuesSolved / issuesDetected) * 100)}
                        className="rounded"
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <Box className="d-flex col-sm-10">
                        <p>
                          {issuesSolved} / {issueDetailsList.length}
                        </p>
                        <p className="fw-lighter ms-2 small">issue solved</p>
                      </Box>
                      <Box className="col-sm-4">
                        <p>
                          {((issuesSolved / issuesDetected) * 100).toFixed()} %
                        </p>
                      </Box>
                    </Box>
                  </div>
                );
              })}
            </TabPanel>
          </div>
        </div>
      )}
    </div>
  );
}
