import * as React from "react";
import "./App.css";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
//import API from "./utils/api";
// pages
import { useAuth } from "./utils/auth";
import Home from "./pages/Home";
import Login from "./pages/login";
import Details from "./pages/details";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import QrCodeScannerRoundedIcon from "@mui/icons-material/QrCodeScannerRounded";

import Planogram from "./pages/Planogram";
import NotFound from "./pages/error.page";
import Health from "./pages/Health";

import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const { token } = useAuth();

  return (
    <Routes>
      {!token ? (
        <Route element={<Login />} path="/" />
      ) : (
        <Route>
          <Route element={<SideBarLeft />}>
            <Route path="/home" element={<Home />}>
              <Route path="scan/:id/" element={<Details />}>
                <Route path="planogram/:id" element={<Planogram />} />
              </Route>
              <Route path="planogram/:id" element={<Planogram />} />
            </Route>
          </Route>
        </Route>
      )}
      <Route element={<Health />} path="/health" />
      <Route element={<NotFound />} path="*" />
    </Routes>
  );
}

function SideBarLeft() {
  const { token, logout } = useAuth();

  const navigate = useNavigate();

  if (!token) {
    return <Navigate to="/" />;
  }

  return (
    <div className="d-flex" style={{ height: "100vh" }}>
      <div className="col-auto">
        <div className="drawer">
          <div className="logo-box d-flex flex-row">
            <image className="bme-logo" alt="bme-logo" />
          </div>
          <div
            className="d-flex flex-row justify-content-center align-items-center"
            style={{ cursor: "pointer", marginTop: 'calc(30px + 0.38rem)' }}
            onClick={() => navigate("/home")}
          >
            <QrCodeScannerRoundedIcon />
            <p className="ms-2">Store scans</p>
          </div>
          <div
            className="d-flex align-items-end"
            style={{ height: "calc(100vh - 185px)", width: "100%" }}
          >
            <div
              className="d-flex flex-row justify-content-center align-items-center w-100"
              style={{ cursor: "pointer"  }}
              onClick={logout}
            >
              <ExitToAppRoundedIcon />
              <p className="ms-2">Log out</p>
            </div>
          </div>
        </div>
        <div className="header d-flex flex-row align-items-center">
          <div className="col-md-6 col-lg-8" style={{ paddingLeft: "20px" }}>
            <p className="fw-bold fs-4 mb-0">Dashboard</p>
          </div>
        </div>
        <div className="content">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
export default App;
