import { Snackbar } from "@mui/material";
import React from "react";
import MuiAlert from "@mui/material/Alert";

function Alert({ message, show, type, handleClose, backgroundColor, color }) {
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = React.useState({
    vertical: "bottom",
    horizontal: "center",
  });
  const { vertical, horizontal } = state;

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={show}
      autoHideDuration={3000}
      key={vertical + horizontal}
      onClose={handleClose}
    >
      <Alert severity={type} style={{ color: color, backgroundColor: backgroundColor, fontWeight: 'bold' }}>{message}</Alert>
    </Snackbar>
  );
}

export default Alert