import "../login.css";

import React from "react";
import { useAuth } from "../utils/auth";
import { API } from "../api/api";
import Alert from "../component/alert";

export default function Login() {
  const { login } = useAuth();

  const [email, setEmail] = React.useState();
  const [password, setPassword] = React.useState();

  const [alertType, setAlertType] = React.useState("");
  const [alertShow, setAlertShow] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");

  const headers = {
    Accept : 'application/json',
    'Content-Type': 'application/json',
  };


  async function handleSubmit() {
    await fetch(API + "/nemesis/auth", {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        username: email,
        password: password,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          return response.text();
        }
      })
      .then((tkn) => {
        if (tkn === "Wrong username or password." || tkn === undefined) {
          setAlertMessage(tkn);
          setAlertType("error");
          setAlertShow(true);
        } else {
          login({ token: tkn.jwt });
        }
      })
      .catch((e) => {
        setAlertMessage(`Impossible to reach the server: ${e}`);
        setAlertType("error");
        setAlertShow(true);
      });
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertShow(false);
  };

  return (
    <div className="w-100 d-flex align-items-center justify-content-center body">
      <Alert
        message={alertMessage}
        show={alertShow}
        type={alertType}
        handleClose={handleClose}
        color={alertType === 'success' ? '#2DB417' : '#EB5858'}
        backgroundColor={alertType === 'success' ? '#DFFFDA' : '#FEE9E7'}
      />
      <div className="login-box d-flex flex-column">
        <div className="d-flex justify-content-center align-items-center bme-logo-box mt-4">
          <image className="bme-logo" alt="bme-logo" />
        </div>
        <div className="title-box d-flex justify-content-center mt-4">
          <div className="d-flex flex-column">
            <p className="title">Sign in</p>
            <p className="tagline mt-4">Planogram compliance platform</p>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <div className="d-flex flex-column ">
            <div className="d-flex text-input-box">
              <input
                type="email"
                placeholder="Email"
                className="text-input mt-4 p-3"
                onChange={(eml) => setEmail(eml.target.value)}
              />
            </div>
            <div className="d-flex text-input-box">
              <input
                type="password"
                placeholder="Password"
                className="text-input mt-4 p-3"
                onChange={(psw) => setPassword(psw.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-5">
          <button
            className="d-flex justify-content-center align-items-center sign-button"
            onClick={handleSubmit}
          >
            <p className="button-label">Sign in</p>
          </button>
        </div>
      </div>
    </div>
  );
}
